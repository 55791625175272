import axios from "axios";
import { useEffect, useState } from "react";

const Recomendations = () => {
  const [lastRecomendations, setLastRecomendations] = useState([]);

  useEffect(() => {
    axios
      .get("https://api.steam-rent.com/getRecomendations")
      .then((resp) => setLastRecomendations(resp.data));
  }, []);

  return (
    <div className="short-recomdendtions__container">
      {lastRecomendations.map((r) => (
        <>
          <div
            style={
              r.userEmail && {
                borderRight: "2.5px solid rgba(255, 115, 0, 0.93)",
              }
            }
            className="short-recomendation__item"
          >
            <strong> {r.recommendation}</strong>{" "}
            <p>{new Date(r.date).toLocaleDateString()}</p>
            {r.userEmail && (
              <div className="recomendation-email">{r.userEmail}</div>
            )}
          </div>
        </>
      ))}
    </div>
  );
};

export default Recomendations;
