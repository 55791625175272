const initialState = {
  payments: [],
  sortedPayments: [],
  isLoading: false,
  error: false,
};

export const payemtReducer = (state = initialState, action) => {
  switch (action.type) {
    case paymntActions.FETCH_PAYMENTS:
      return { ...state, isLoading: true };

    case paymntActions.FETCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        payments: action.payload,
        sortedPayments: action.payload,
        isLoading: false,
      };

    case paymntActions.FETCH_PAYMENTS:
      return { ...state, isLoading: false, error: true };

    case paymntActions.FIND_PAYMENTS_BY_EMAIL:
      return {
        ...state,
        sortedPayments: state.payments.filter((item) =>
          item.email.includes(action.payload)
        ),
      };

    default:
      return state;
  }
};

export const paymntActions = {
  FETCH_PAYMENTS: "FETCH_PAYMENTS",
  FETCH_PAYMENTS_ERROR: "FETCH_PAYMENTS_ERROR",
  FETCH_PAYMENTS_SUCCESS: "FETCH_PAYMENTS_SUCCESS",
  FIND_PAYMENTS_BY_EMAIL: "FIND_PAYMENTS_BY_EMAIL",
};
