///

import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";
import GuardItem from "../Components/GuardItem";
import Loader from "../Components/UI/Loader";
////

const GuardsPage = () => {
  const [guards, setGuards] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const checkMail = async () => {
    setIsLoading(true);
    console.log("chacking...");
    await axios
      // .get("http://localhost:8080/checkMail")
      .get("https://api.steam-rent.com/checkMail")
      .then((resp) => setIsLoading(false));
    console.log(isLoading);
    axios
      .post("https://api.steam-rent.com/getGuards", { pass: "lalalaX" })
      .then((resp) => setGuards(resp.data));
    return;
  };

  useEffect(() => {
    checkMail();
  }, []);

  if (guards.length == 0) {
    return <p>Loading...</p>;
  }

  return (
    <div className="guards-page">
      <div className="guards-page__header">
        <div onClick={!isLoading ? checkMail : {}} className="upd-btn">
          update
        </div>
        {isLoading ? <Loader /> : ""}
      </div>
      {guards.map((guardItem) => (
        <GuardItem key={guardItem._id} guardItem={guardItem} />
      ))}
    </div>
  );
};

export default GuardsPage;
