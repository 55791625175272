import axios from "axios";
import { memo, useEffect, useState } from "react";
import { AiFillCaretUp } from "react-icons/ai";
import { AiFillCaretDown } from "react-icons/ai";

const GGStatDay = ({dayData,update}) => {
    const [adSpend,setAdSpend] =    useState(null)
    const [netIncome,setNetIncome] = useState(null)
    const [adInput,setAdInput] = useState('')
    const [inputActive,setInputActive] = useState(false)

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString("ru-RU", {
            year: "numeric",
            month: "2-digit",
            day: "numeric"
        });
    };

    const setDailyAdBudget = async () => {
        console.log('set daily ad budget')
        await  axios.post('https://api.steam-rent.com/admin/setGGad', {
            dayId: dayData._id,
            adSpend: adInput
        })
        
        update()
    }

    useEffect(() => {
        if(dayData.adSpend){
            setNetIncome(dayData.income - dayData.adSpend)
        }
        if(dayData.adSpend == 0){
            setNetIncome(dayData.income)
        }
    },[])

    return(
        <div className="ggstat-page__day">
        <div id={netIncome || netIncome == 0|| dayData.income == 0 ? '':"ggstat-page__day-header-setAdd"} className="ggstat-page__day-header">
           <div className="header-day__net-income">
            {netIncome && netIncome !== 0 && `${netIncome.toFixed(2)}$`}
            </div> 
           <div className="header-day">{formatDate(dayData.date)}</div> 
           <div className="header-day-stat">
                <div className="stat-item">
                {dayData.adSpend && !inputActive || dayData.adSpend == 0 && !inputActive ? <div onClick={() => setInputActive(!inputActive)} style={{cursor:'pointer',display:'flex',alignItems:'center'}}> {(dayData.adSpend).toFixed(2)}</div> : 
                        <div className="header-input-wrapper">
                            {adInput.length > 0 && <div onClick={setDailyAdBudget} className="header-input-btn">ok</div>}
                             <input value={adInput} onChange={(e) => setAdInput(e.target.value)} placeholder='ad' className="day-header-input"/>
                        </div>
                    }
                </div>  
                <div  className="stat-item">
                    {(dayData.income).toFixed(2)}  <AiFillCaretUp/> 
                </div>                 
        </div>
            
        </div>
        {dayData.payments.length > 0 && dayData.payments.map((paymentItem,i) => 
        <div className="ggstat-page__payment">
            <p>{++i} {paymentItem.title}</p>
            <p>{paymentItem.amount} USD</p>
        </div>
        )}
    </div>
    )
}




export default memo(GGStatDay)