import React, { useState } from "react";
import { memo } from "react";

const UserItem = ({ user }) => {
  const [open, setOpen] = useState(false);

  return (
    <div id={open ? "open_p" : ""} className="user__item">
      <div className="top">
        <p className="email">{user.userEmail}</p>
        <p className="fullmail">{user.userEmail}</p>
        <p style={{ cursor: "pointer" }} onClick={() => setOpen(!open)}>
          payments: {user.payments.length}
        </p>
        <p> {user.income} ₸</p>
        <>
          {user.payments.at(-1).date}
          <img src={require("../assets/img/mail.png")} alt="" />
        </>
      </div>
      {open ? (
        user.payments.map((payment) => (
          <div className="mini-payment">
            <p>{payment.title ? payment.title : NaN}</p>
            <p>{payment.duration}</p>
            <p>{payment.date}</p>
            <p>{payment.amount}</p>
            {payment.currency}
          </div>
        ))
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default memo(UserItem);
