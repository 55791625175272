import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../UI/Loader";
import { useMemo } from "react";

const Boxes = () => {
  const [income, setIncome] = useState(null);
  const [users, setUsers] = useState(null);
  const [duration, setDuration] = useState(28);
  const [duration2, setDuration2] = useState(28);
  const [numberOfNewUsers, setNumberOfNewUsers] = useState();
  const [percentageOfSuccesPayemtns, setPercentageOfSuccessPayemnts] =
    useState();
  const [incomeUah, setIncomeUah] = useState(null);

  useEffect(() => {
    axios
      .post("https://api.steam-rent.com/incomestat", { duration })
      .then((resp) => setIncome(resp.data));
  }, [duration]);

  useEffect(() => {
    axios
      .post("https://api.steam-rent.com/incomeusersstat", {
        duration: duration2,
      })
      .then((resp) => setUsers(resp.data));
  }, [duration2]);

  useEffect(() => {
    axios
      .get("https://api.steam-rent.com/getNumberOfNewUsers")
      .then((resp) => setNumberOfNewUsers(resp.data.newUsers));
    axios
      .get("https://api.steam-rent.com/percentageOfSuccessPayments")
      .then((resp) => setPercentageOfSuccessPayemnts(resp.data.percentage));
  }, []);

  axios
    .get("https://api.steam-rent.com/admin/getUah30")
    .then((resp) => setIncomeUah(resp.data.sum));

  return (
    <div className="statistics__boxes__container">
      <div
        onClick={() => setDuration(duration === 28 ? 7 : 28)}
        className="box-item"
      >
        {income === null ? (
          <Loader />
        ) : (
          <>
            <img
              src={require(`../../assets/img/${
                income.direction === "min" ? "decrease" : "increase"
              }.png`)}
            />
            <div className="stat">
              <p>{income.percentage}%</p>
            </div>
          </>
        )}
      </div>
      <div
        onClick={() => setDuration2(duration2 === 28 ? 7 : 28)}
        className="box-item"
      >
        {users === null ? (
          <Loader />
        ) : (
          <>
            <img
              src={require(`../../assets/img/${
                users.direction === "min" ? "decrease" : "increase"
              }.png`)}
            />
            <div className="stat">
              <p>{users.percentage}%u</p>
            </div>
          </>
        )}
      </div>
      <div style={{ fontSize: "0.9em" }} className="box-item">
        {income === null ? (
          <Loader />
        ) : (
          <div className="numberOf">{percentageOfSuccesPayemtns}%</div>
        )}
      </div>
      <div className="box-item">
        {income === null ? (
          <Loader />
        ) : (
          <div className="numberOf">{numberOfNewUsers}</div>
        )}
      </div>
      <div className="box-item">
        <div className="numberOf">{Number(incomeUah).toFixed(0)}</div>
      </div>
    </div>
  );
};

export default Boxes;
