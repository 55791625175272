import React from "react";

const ProductIncomeItem = ({ game }) => {
  console.log(game);
  return (
    <div className="game__item">
      <div className="game_item_container">
        <p>{game.title}</p>
        <p>{game.income}</p>
      </div>
    </div>
  );
};

export default ProductIncomeItem;
