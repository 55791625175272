import React, { useState } from "react";
import CheckBoxCustom from "./UI/CheckBoxCustom";
import axios from "axios";

const NewPaymentModal = ({ modalVisibility, setModalVisibility }) => {
  const [sendMail, setSendMail] = useState(false);
  const [email, setEmail] = useState("");
  const [product, setProduct] = useState("");
  const [duration, setDuration] = useState("");
  const [date, setDate] = useState("");
  const [amount, setAmount] = useState("");
  const [currency, setCurrency] = useState("");
  const [category, setCategory] = useState("");
  const [correctness, setCorrectness] = useState(true);

  const sendData = () => {
    const newPayment = {
      email: email.toLowerCase(),
      product,
      duration,
      date,
      amount,
      currency,
      category,
      sendMail,
    };
    console.log(newPayment);
    axios
      .post("https://api.steam-rent.com/addpaymentfromadmin", newPayment)
      .then((resp) =>
        resp.data ? setModalVisibility(false) : setCorrectness(false)
      );
    setTimeout(() => {
      setCorrectness(true);
    }, 500);
  };

  return (
    <div
      onClick={() => setModalVisibility(!modalVisibility)}
      style={modalVisibility ? { zIndex: 1 } : { opacity: 0 }}
      className="modal"
      id={correctness ? "" : "shake"}
    >
      <div onClick={(e) => e.stopPropagation()} className="modal-content">
        <input
          onChange={(e) => setEmail(e.target.value)}
          value={email}
          placeholder="User email"
          type="email"
        />
        <input
          onChange={(e) => setProduct(e.target.value)}
          value={product}
          placeholder="Product"
          type="text"
        />
        <input
          onChange={(e) => setCategory(e.target.value)}
          value={category}
          placeholder="Category (netflix,game,spotify)"
          type="text"
        />
        <input
          onChange={(e) => setDuration(e.target.value)}
          value={duration}
          placeholder="Duration (1,7,30)"
          type="text"
          style={
            category.toLocaleLowerCase() == "game" ? {} : { display: "none" }
          }
        />
        <input
          onChange={(e) => setDate(e.target.value)}
          value={date}
          placeholder="Date(m.d.y)"
          type="text"
        />
        <input
          onChange={(e) => setAmount(e.target.value)}
          value={amount}
          placeholder="Amount"
          type="text"
        />
        <input
          onChange={(e) => setCurrency(e.target.value)}
          value={currency}
          placeholder="Currency (UAH,R,USD)"
          type="text"
        />

        <div style={{ display: "flex" }}>
        <CheckBoxCustom state={sendMail} setState={setSendMail}/>  <p> mail</p>
        </div>
        <button onClick={sendData}>OK</button>
      </div>
    </div>
  );
};

export default NewPaymentModal;
