const initialState = {
  users: [],
  isLoading: false,
  error: false,
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActions.FETCH_USERS_SUCCESS:
      return { ...state, users: [...action.payload], isLoading: false };
    case userActions.FETCH_USERS:
      return { ...state, isLoading: true };
    case userActions.FETCH_USERS_ERROR:
      return { ...state, isLoading: false, error: true };
    default:
      return state;
  }
};

export const userActions = {
  FETCH_USERS: "FETCH_USERS",
  FETCH_USERS_SUCCESS: "FETCH_USERS_SUCCESS",
  FETCH_USERS_ERROR: "FETCH_USERS_ERROR",
};
