import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPayments } from "../store/action-creators/payments";
import PaymentsList from "../Components/PaymentsList";
import { paymntActions } from "../store/reducers/paymentsReducer";
import NewPaymentModal from "../Components/NewPaymentModal";
//

const PaymentsPage = () => {
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState("");
  const [modalVisibility, setModalVisibility] = useState(false);
  useEffect(() => {
    dispatch(fetchPayments());
  }, []);

  useEffect(() => {
    dispatch({
      type: paymntActions.FIND_PAYMENTS_BY_EMAIL,
      payload: inputValue,
    });
  }, [inputValue]);

  return (
    <>
      <div className="payment-page">
        <div className="payments-header">
          <input
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            type="text"
            placeholder="Email"
            className="justInput"
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              onClick={() => dispatch(fetchPayments())}
              src={require("../assets/img/updatess.png")}
              alt=""
            />
            <button onClick={() => setModalVisibility(true)} className="btn">
              Add New
            </button>
          </div>
        </div>
        <PaymentsList />
      </div>
      <NewPaymentModal
        modalVisibility={modalVisibility}
        setModalVisibility={setModalVisibility}
      />
    </>
  );
};

export default PaymentsPage;
