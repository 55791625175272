import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTasks } from "../store/action-creators/taskActions.js";
import PlanItem from "./PlanItem.jsx";
import Loading from "./UI/LoadingAnim.jsx";

const TasksList = () => {
  const dispatch = useDispatch();
  const { tasks, isLoading } = useSelector((state) => state.task);

  useEffect(() => {
    dispatch(fetchTasks());
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return tasks.map((task) => <PlanItem item={task} />);
};

export default React.memo(TasksList);
