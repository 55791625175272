import React, { useEffect } from "react";
import { useMemo } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import GamesList from "../Components/GamesList";
import NewGameModal from "../Components/NewGameModal";
import Search from "../Components/UI/Search";
import { fetchGames } from "../store/action-creators/gamesActions";
import axios from "axios";
import LoadingAnim from "../Components/UI/LoadingAnim";

const GamesPage = () => {
  const { games } = useSelector((state) => state.game);
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [option, setOption] = useState("games");
  const [loading,setLoading] = useState(false)
  const [monthData,setMonthData] = useState(null)
  const filteredGames = useMemo(() => {
    if(option == "month"){
      return
    }
    let f = games.filter((game) =>
      game.title.toLowerCase().includes(inputValue.toLowerCase())
    );
    if (option == "games") {
      return f;
    } else {
      return f.sort((a, b) => Number(b.income) - Number(a.income));
    }
  }, [inputValue, games, option]);


  const getMonthData = async() => {
    setOption(option == "games" ? "month" : "games")
    if(monthData) return 
    //
    setLoading(true)
    const data = await axios.post('https://api.steam-rent.com/admin/getGamesStat',{timeout: 1600000}).then(resp => resp.data)
    setMonthData(data)
    console.log(data)
    setLoading(false)
  }

  useEffect(() => {
    dispatch(fetchGames());
  }, []);
  

  return (
    <div className="games-page">
      <div className="games-page__header">
        <div className="">
          <button
            onClick={() => setOption(option == "games" ? "income" : "games")}
            className="btn"
            style={{margin:'5px'}}
          >
            Income
          </button>
          <button
            onClick={() =>getMonthData()}
            className="btn"
            id={option == "month" ? "btn-active" : ""}
          >
            Month
          </button>
        </div>
        <div>
          <Search searchQuery={inputValue} setSearchQuery={setInputValue} />
        </div>
        <button onClick={() => setVisibility(!visibility)} className="btn">
          {option == "games" ? "Add New" : games.length}
        </button>
      </div>
      {option !== 'month' &&<GamesList option={option} filteredGames={filteredGames} />}
      <NewGameModal visibility={visibility} setVisibility={setVisibility} />
      {loading && <LoadingAnim />}
      {/* '''''''''''' */}

      {monthData && monthData.map(game => 
        <div className="game__item">
          <div key={game._id} className="game_item_container">
          {game.title}
          
            <p> <span style={{marginRight:'30px'}}>{game.sales}</span>{game.income.toFixed(2)}</p>
          </div>
        </div>
      ) }
    </div>
  );
};

export default GamesPage;
