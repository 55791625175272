////
import axios from "axios";
import { paymntActions } from "../reducers/paymentsReducer";

export const fetchPayments = () => {
  return async (dispatch) => {
    dispatch({ type: paymntActions.FETCH_PAYMENTS });
    try {
      const resp = await axios.get("https://api.steam-rent.com/getPayments");
      console.log(resp);
      dispatch({
        type: paymntActions.FETCH_PAYMENTS_SUCCESS,
        payload: resp.data,
      });
    } catch (e) {
      dispatch({ type: paymntActions.FETCH_PAYMENTS_ERROR });
    }
  };
};
