import axios from "axios";

const TransactionItem = ({ item, upd }) => {
  const accept = async () => {
    axios
      .post("https://api.steam-rent.com/acceptTransaction", {
        // .post("http://localhost:8080/acceptTransaction", {
        Email: item.userEmail,
        duration: item.duration,
        productId: item.productId,
        amount: item.amount,
        productTitle: item.productTitle,
        isOnline: item.isOnline ? item.isOnline : false,
        transactionId: item._id,
      })
      .then(() => upd());
  };

  const cancel = async () => {
    axios
      .post("https://api.steam-rent.com/cancelTransaction", {
        transactionId: item._id,
      })
      .then(() => upd());
  };
  return (
    <div className="transaction-item">
      <span>{item.productTitle}</span>
      <p className="email">{item.userEmail}</p>
      <p>{item.amount}₴</p>
      <p className="time">{item.time}</p>
      <div className="options">
        <p className="ac" onClick={accept}>
          accept
        </p>{" "}
        <p onClick={() => cancel()}>cancel</p>
      </div>
    </div>
  );
};

export default TransactionItem;
