import axios from "axios";
import { authActions } from "../reducers/authReducer";

export const logInAction = (authData) => {
  return async (dispath) => {
    try {
      const authStatus = await axios
        .post("https://api.steam-rent.com/login", authData)
        .then((resp) => resp.data);
      if (authStatus) {
        dispath({ type: authActions.logIn, payload: authStatus });
        localStorage.setItem("auth", JSON.stringify(authData));
      }
    } catch (e) {
      console.log(e);
      dispath({ type: authActions.error });
    }
  };
};
