


const Loader2 = () => {
    return(
        <div className="loader2-wrapper">
            <div className="loader2"></div>
        </div>
    )
}

export default Loader2