import axios from "axios";
import react, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { fetchTasks, refresh } from "../store/action-creators/taskActions";

const PlanItem = ({ item }) => {
  ///
  const dispatch = useDispatch();
  const timeLeft = useMemo(() => {
    return Math.round((Date.parse(item.date) - Date.now()) / 86400000);
  }, []);

  const timeline = useMemo(() => {
    return 100 - (timeLeft / item.duration) * 100;
  });

  const del = () => {
    if (window.confirm()) {
      axios
        .post("https://api.steam-rent.com/deltask", { id: item._id })
        .then((resp) => dispatch(refresh()));
    }
  };

  return (
    <div className="plan__item">
      <div className="infrom">
        <div className="part">
          <p> {item.title}</p>
          <img
            className="key"
            src={require("../assets/img/keyicon.png")}
            alt=""
          />
        </div>
        <p>{timeLeft} days left</p>
        <img
          onClick={() => del()}
          src={require("../assets/img/delete2.png")}
          style={timeLeft > 0 ? { filter: "opacity(0.3)" } : {}}
        />
      </div>
      <div
        style={
          timeLeft < 0
            ? {
                width: "100%",
                background:
                  "linear-gradient(90deg,rgba(0, 255, 0, 0.508),rgba(255, 0, 0, 0.779))",
              }
            : { width: `${timeline}%` }
        }
        className="timeline"
      ></div>
    </div>
  );
};

export default PlanItem;
