import { useState } from "react"

const ClickInput = ({text,setText}) => {
    const [isAcitve,setIsActive] = useState(false)
    const [inputValue,setInputValue] = useState('')

    const setNewText = () => {
        setText(inputValue)
        setIsActive(false)
        setInputValue('')
    }

    
    return(
        <div className="click-input-wrp">
           {!isAcitve ? <span onClick={() => setIsActive(true)} className="click-input-text">{text ? text : '...'}</span> :
            <div className="input-wrp"><input autofocus type="text" inputValue={text} onChange={(e) => setInputValue(e.target.value)}/> <span style={inputValue.length > 2 ? {visibility:'visible'}:{visibility:'hidden'}} className="click-input-btn" onClick={setNewText}>k</span></div>} 
        </div>
    )
}


export default ClickInput