const initialState = {
  games: [],
  isLoading: false,
  error: false,
};

export const gamesReducer = (state = initialState, action) => {
  switch (action.type) {
    case gamesActions.FETCH_GAMES:
      return { ...state, isLoading: true };
    case gamesActions.FETCH_GAMES_SUCCESS:
      return { ...state, isLoading: false, games: action.payload };
    case gamesActions.FETCH_GAMES_ERROR:
      return { ...state, isLoading: false, error: true };
    case gamesActions.UPDATE:
      return { ...state, games: action.payload };

    default:
      return state;
  }
};

export const gamesActions = {
  FETCH_GAMES: "FETCH_GAMES",
  FETCH_GAMES_SUCCESS: "FETCH_GAMES_SUCCESS",
  FETCH_GAMES_ERROR: "FETCH_GAMES_ERROR",
  UPDATE: "UPDATE",
};
