import { useState } from "react"

const LinkStatItem = ({data}) => {
    console.log(data)
    // const [showPaymets,setShowPayments] = useState(false)
    return(
        <div className="link-stat-item">
           <span className="stat-item__name">{data.name}</span>
           {/* <p>UniqueClick: {data.clicks}</p> */}
           <p>uClicks: {data.clicks}</p>

           <p>Clicks: {data.clicks} <span className="new-clicks">{data.todaysClicks && data.todaysClicks.clicks > 0 && ` +${data.todaysClicks.clicks}`}</span></p>

          <div className="stat-item__sales">
            <p>Sales {data.salesCount}</p>
            {data.payments.length > 0 && 
            <div className="stat-item__payments">
                {data.payments.map(payment => 
                    <div className="stat-item__payments-item">
                        <p>{payment.title}</p>
                        <p className="item-price">{payment.amount}</p>
                    </div>
                )}
            </div>
            }
          </div>

           <p>Conversion {((data.salesCount / data.clicks).toFixed(2) * 100).toFixed(1)}%</p>
           <div className="stat-item__money">
            {data.moneySpent}   <img src="https://img.icons8.com/pulsar-gradient/48/money.png" alt="" />
           </div>
        </div>
    )
}


export default LinkStatItem