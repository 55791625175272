import { applyMiddleware, combineReducers, legacy_createStore } from "redux";
import { userReducer } from "./userReducer";
import { composeWithDevTools } from "@redux-devtools/extension";
import { payemtReducer } from "./paymentsReducer";
import thunk from "redux-thunk";
import { gamesReducer } from "./gamesReducer";
import { authReducer } from "./authReducer";
import { taskReducer } from "./tasksReducer";

const rootReducer = combineReducers({
  user: userReducer,
  payment: payemtReducer,
  game: gamesReducer,
  auth: authReducer,
  task: taskReducer,
});

export const store = legacy_createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
