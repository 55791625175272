///

import { useState } from "react";

///

const GuardItem = ({ guardItem }) => {
  const [copyAnim, setCopyAnim] = useState(false);

  const copy = () => {
    navigator.clipboard.writeText(guardItem.guard);
    setCopyAnim(true);
    console.log("copy");
  };

  return (
    <div className="guard__item">
      <div className="block">
        <p>{guardItem.login} </p>
        <p>login at {guardItem.date}</p>
      </div>
      <p className={guardItem.platform == "EpicGames" ? "ep" : "st"}>
        {guardItem.platform}
      </p>

      <span
        id={!copyAnim ? "" : "copyAnim"}
        onClick={() => copy()}
        onAnimationEnd={() => setCopyAnim(false)}
        className={guardItem.action}
      >
        {guardItem.action == "LogIn" ? guardItem.guard : "Support"}
      </span>
    </div>
  );
};

export default GuardItem;
