import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { logInAction } from "../store/action-creators/authActions";
import axios from "axios";

const AuthPage = () => {
  const [login, setLogin] = useState("");
  const [pass, setPass] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get("http://localhost:8080/getMail")
      .then((resp) => console.log(resp.data));
  }, []);

  return (
    <div className="AuthPage">
      <div className="auth__container">
        <h3>Login</h3>
        <input
          onChange={(e) => setLogin(e.target.value)}
          placeholder="Login"
          type="text"
          name=""
          id=""
        />
        <input
          onChange={(e) => setPass(e.target.value)}
          placeholder="Pass"
          type="password"
          name=""
          id=""
        />
        <button onClick={() => dispatch(logInAction({ login, pass }))}>
          Log In
        </button>
      </div>
      <img src={require("../assets/img/ghost.png")} alt="" />
    </div>
  );
};

export default AuthPage;
