import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import TransactionItem from "../Components/TransactionItem";

const TransactionsPage = () => {
  const [transactions, setTransactions] = useState([]);

  const getTransactions = async () => {
    axios
      .get("https://api.steam-rent.com/getPreTransactions")
      .then((resp) => setTransactions(resp.data.tr));
  };
  useEffect(() => {
    getTransactions();
  }, []);

  if (transactions.length === 0) {
    return (
      <div className="nothing">
        <img src={require("../assets/img/empty.png")} />
      </div>
    );
  }
  return (
    <div className="transactions-page">
      {transactions.map((el) => (
        <TransactionItem upd={getTransactions} item={el} />
      ))}
    </div>
  );
};

export default TransactionsPage;
