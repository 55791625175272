import React from "react";
import cl from "./Loading.module.scss";
// import "./Loading.scss";

const Loading = () => {
  return (
    <React.Fragment>
      <div className={cl.loader} aria-hidden="true">
        <div className={cl.loader__sq}></div>
        <div className={cl.loader__sq}></div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(Loading);
