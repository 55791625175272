import { useEffect, useState } from "react";
import TasksList from "../Components/tasksList";
import axios from "axios";

const PlanPage = () => {
  return (
    <div className="plan-page">
      <TasksList />
    </div>
  );
};

export default PlanPage;
