import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Search from "../Components/UI/Search";
import UsersList from "../Components/UsersList";
import { fetchUsers } from "../store/action-creators/userActions";
import { fetchPayments } from "../store/action-creators/payments";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
///////////
///////////
/////////
const UsersPage = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.user);
  const { payments } = useSelector((state) => state.payment);
  const [sortOptions, setSortOptions] = useState("income");
  const [searchQuery, setSearchQuery] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [sts, setSts] = useState(false);
  const [averageOfPurchases, setAverageOfPurchases] = useState("loading");
  const [regUsers, setRegUsers] = useState(0);

  const filteredUsers = useMemo(() => {
    return users
      .filter((user) => user.userEmail.includes(searchQuery))
      .sort((a, b) => {
        if (sortOptions == "payments") {
          return b[sortOptions].length - a[sortOptions].length;
        }
        return b[sortOptions] - a[sortOptions];
      });
  }, [sortOptions, users, searchQuery]);

  const activeUsers = useMemo(() => {
    return users.filter((user) => {
      return user.payments.length >= 2;
    });
  }, [users]);

  const getAverageOfPurchases = () => {
    if (averageOfPurchases === "loading") {
      axios
        .get("https://api.steam-rent.com/averageOfOldUsersPurchases")
        .then((resp) => setAverageOfPurchases(resp.data.average));
    }
  };

  const getNumberOfRegUsers = () => {
    try {
      axios
        .get("https://api.steam-rent.com/getNumberOfRegUsers")
        .then((resp) => setRegUsers(resp.data.numberOfRegUsers));
    } catch (e) {
      console.log("getNumberOfRegUsers err");
    }
  };

  useEffect(() => {
    getNumberOfRegUsers();
    dispatch(fetchUsers());
    dispatch(fetchPayments());
    setSearchQuery(
      searchParams.get("email")
        ? searchParams.get("email").toLocaleLowerCase()
        : ""
    );
  }, []);

  return (
    <div className="games-page">
      <div className="users-page__header">
        <div style={{ display: "flex", height: "28px" }}>
          <h4>{users.length}</h4>
          {/* <h4>
            {" "}
            {!sts ? users.length : (payments.length / users.length).toFixed(1)}
          </h4> */}

          <img src={require("../assets/img/user.png")} alt="" />

          <h4 style={{ marginLeft: "15px" }}>
            {" "}
            {!sts
              ? activeUsers.length
              : ((activeUsers.length / filteredUsers.length) * 100).toFixed(1) +
                "%"}
          </h4>
          <img
            style={{ filter: " hue-rotate(221deg)" }}
            src={require("../assets/img/user.png")}
            alt=""
            className="hrt"
            onClick={() => {
              setSts(!sts);
              getAverageOfPurchases();
            }}
          />
          <h5> {!sts ? "" : averageOfPurchases}</h5>

          <h4 style={{ marginLeft: "15px" }}>
            {!sts
              ? regUsers
              : ((regUsers / filteredUsers.length) * 100).toFixed(1) + "%"}
          </h4>
          <img
            style={{ filter: " hue-rotate(171deg)" }}
            src={require("../assets/img/user.png")}
            alt=""
            className="hrt"
            onClick={() => {
              setSts(!sts);
              getAverageOfPurchases();
            }}
          />
        </div>
        <Search searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        <img
          style={{ height: "27px" }}
          src={require("../assets/img/sorticon.png")}
          onClick={() =>
            sortOptions == "income"
              ? setSortOptions("payments")
              : setSortOptions("income")
          }
        />
      </div>
      <UsersList filteredUsers={filteredUsers} />
    </div>
  );
};

export default UsersPage;
