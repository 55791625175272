import React from "react";

const Search = ({ searchQuery, setSearchQuery }) => {
  return (
    <input
      onChange={(e) => setSearchQuery(e.target.value)}
      value={searchQuery}
      placeholder="Search"
      type="text"
      id="search"
    />
  );
};

export default Search;
